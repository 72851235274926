  .prestation{
    padding-top: 6.5rem;
  }

  .prestation__title{
    background-color: #fee533;
    color: #e5322d;
    font-size: 1.8rem;
    font-weight: bolder;
    margin: 0rem 0 2rem 0;
    text-transform: uppercase;
    padding: 1rem;
    text-align: center;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
  }

  .card{
    margin: 0 10%;
  }

  .card__title{
    text-align: center;
    font-size: 2rem;
    margin: 1rem;
    text-transform: uppercase;
  }

  .card__container{
    background-color: #393838;
    margin-top: -2rem;
    position: relative;
    border-radius: 2rem 2rem 3rem 3rem;
    margin-bottom: 2rem;
    padding: 1.5rem;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
  }

  .card__img{
    max-width: 100%;
    border-radius: 3rem 3rem 0 0;
    box-shadow: 0px 0px 8px 4px rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
  }

  .card__text{
    color: #fff;
    padding: 1.2em;
    text-align: justify;
  }

  @media screen and (min-width:1440px){
    .prestation{
      margin: 0 15%;
    }
  }

  @media screen and (max-width:768px)
  {
    .card{
      margin: 0;
    }

    .card__container{
      padding: 1rem;
    }
  }
