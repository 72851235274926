.contact {
  padding-top: 6.5rem;
  min-height: 100vh;
}

.contact_title{
  background-color: #fee533;
    color: #e5322d;
    font-size: 1.8rem;
    font-weight: bolder;
    margin: 0rem 0 1.5rem 0;
    text-transform: uppercase;
    padding: 1rem;
    text-align: center;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
}

.contact_text{
  text-align: center;
  margin: 1rem 0;
  font-size: 1.5rem;
  padding: 1rem;
}

.contact_info{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.contact_img{
  min-width: 10rem;
  width: 55%;
  margin: 0 1.5rem 2rem 1.5rem;
  box-shadow: 0 4px 8px 0 rgb(0 0 0), 0 6px 20px 0 rgb(0 0 0 / 68%);
}

.contact_info-text{
  margin: 0 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.contact_title-info{
  font-size: 1.6rem;
  padding: 0 0 1rem ;
  text-transform: uppercase;
  font-weight: bold;
}

.contact_subtitle{
  font-size: 1.2rem;
  padding: 0.7rem 0;
  text-transform: uppercase;
  text-decoration: underline;
  font-weight: bold;
}

.contact_subtitle-text{
}

.contact_btn{
  background-color: #04AA6D;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin: 0.3rem 0.5rem;
}

.contact_form{
  text-align: center;
  width: 60%;
  margin: auto;
}

.contact_input[type=text], select, .contact_textarea{
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  resize: vertical;
  background: #2b2b2b;
  color: white;
}

.contact_label {
  padding: 12px 12px 12px 0;
  display: inline-block;
}

.contact_textarea{
  min-height: 125px;
}

.contact_input[type=submit] {
  background-color: #04AA6D;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin: 1rem 0;
}

.contact_form_label {
  float: left;
}

.contact_form_input {
  float: left;
  width: 100%;
}

.contact_form:after {
  content: "";
  display: table;
  clear: both;
}

.social_text{
  text-align: center;
  font-size: 1.2rem;
  margin: 1.5rem;
  font-weight: bold;
}

.social_list{
  text-align: center;
}

.social_img{
  width: 40px;
  border-radius: 100%;
  margin: 0 0 1rem;
}

@media screen and (min-width:1440px){
  .contact{
    margin: 0 15%;
  }
}

@media screen and (max-width: 600px) {
  .contact_label, .contact_form_input, .contact_input[type=submit] {
    width: 100%;
  }
}

@media screen and (max-width: 425px){
  .contact_img{
    width: 80%;
  }
}
