.navbar {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  min-height: 75px;
  padding: 0.3rem;
  background-color: #fee533;
  color: rgb(0, 0, 0);
  z-index: 2;
  border-bottom: 1px solid;
}

.navbar img{
  width: 6rem;
}

.navbar__links{
  display: flex;
}

.navbar__link {
  text-transform: uppercase;
  padding: 0 0.5rem;
  color: inherit;
  font-size: 1.1rem;

  &:hover{
    border-bottom: solid 0.1em;
    border-top: solid 0.1em;
    
    &:active{
    border-bottom: solid 0.1em;
    border-top: solid 0.1em;
    }
  }
}

.navbar__burger {
  width: 40px;
  height: 40px; 
  background: transparent;
  border: none;
  color:inherit;
}

@media screen and (max-width:768px) {

  .navbar {
    color: rgb(0, 0, 0);
  }

  .navbar__links {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    right: -100vw;
    bottom: 0;
    width: 0;
    height: 100vh;
    padding: 2rem;
    visibility: hidden;
    background-color: rgb(184, 26, 26);
    transition: all 1.2s ease-out;
  }  

  .show-nav .navbar__links {
    width: 100vw;
    right: 0;
    visibility: visible;
  }

  .navbar__link {
    display: block;
    padding: 1.5rem;
    font-size: 5vw;
  }

  .navbar__burger {
    display: block;
    position: fixed;
    top: 1rem;
    right: 1rem;
  }

  .navbar__burger:hover {
    cursor: pointer;
  }

  .burger-bar,
  .burger-bar::before,
  .burger-bar::after {
    display: block;
    width: 40px;
    height: 3px;
    position: relative;
    border-radius: 3px;
    background: rgb(0, 0, 0);
    transition: all .5s ease-in-out;
  }

  .burger-bar::before,
  .burger-bar::after {
    content: "";
    position: absolute;
    left: 0;
  }

  .burger-bar::before {
    transform: translateY(-12px);
  }

  .burger-bar::after {
    transform: translateY(12px);
  }

  .show-nav .burger-bar {
    width: 0;
    background: transparent;
  }

  .show-nav .burger-bar::before {
    transform: rotate(45deg);
  }

  .show-nav .burger-bar::after {
    transform: rotate(-45deg);
  }
}
