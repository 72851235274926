.footer{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: #000000;
  color: #fff;
  font-size: 1rem;
  text-transform: uppercase;
  width: 100%;
  padding: 1rem;
  min-height: 75px;
  height: 100%;
}

.footer__links{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 3rem;
}

.footer__item{
  margin: 0.5rem;
}

.footer__item:hover{
  text-decoration: underline;
}

.footer__information{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  padding: 1rem;
}

.footer__title{
  margin: 0.8rem 0;
  text-decoration: underline;
}

.footer__text{
  font-size: 0.8rem;
  text-align: center;
}

.footer__lists{
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: auto;
  padding: 1rem;
}

.footer__bloc{
  display: flex;
    align-items: center;
    flex-direction: column;
    margin: auto;
    padding: 0.5rem;
}

.footer__logo-items{
  display: flex;
  font-size: 0.7rem;
  flex-direction: column;
  margin: 0.3rem;
  text-align: center;
}

.footer__logo{
  width: 30%;
}

.footer__items{
  margin: 0.3rem;
}

.footer__container{
  display: flex;
  flex-wrap: wrap;
}
