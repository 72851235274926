.home__container{
  display: flex;
  color: rgb(255, 255, 255);
}

.home__img{
  width: 50vw;
  margin: 10rem 0 3rem 0;
}

.home__bloc--title{
  margin: auto;
}

.home__title{
  text-align: center;
}

.home__title--img{
  text-align: center;
  width: 100%;
  margin-top: 6.5rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.home__title--h3{
  color: black;
  margin: 1rem 2rem;
  text-align: center;
  padding: 1rem;
  font-size: 1.2rem;
  font-weight: bold;
}

.home__btn{
  display: flex;
  margin: 1rem auto 4rem;
  background-color: #c91812e4;
  padding: 0.8rem;
  border-radius: 0.5rem;
  color: #ffffff;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
}

.home__btn:hover{
  background-color: #8c0a05;
  color: #ffffff;
  transition-duration: 500ms;
  transition-property: background-color;
  box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
}

.home__bloc--text{
  background-color: #393838;
  padding: 3rem 3rem;
  box-shadow: 2px 1px 8px 0px;
}

.home__text--h1{
  text-align: center;
  color: #e5322d;
  padding: 1rem;
  font-size: 1.8rem;
  text-transform: uppercase;
  font-weight: bolder;
  width: 23rem;
  margin: -5rem 0 2rem -3rem;
  background-color: #fee533;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.home__text{
  color: #ffffff;
  text-align: justify;
}

.home__text--h2{
  background-color: #fee533;
  color: #e5322d;
  font-size: 1.8rem;
  font-weight: bolder;
  margin: -2rem -3rem -5rem 0;
  padding: 1rem;
  text-align: center;
  text-transform: uppercase;
  width: 23rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.home__text--h3{
  background-color: #fee533;
  color: #e5322d;
  font-size: 1.8rem;
  font-weight: bolder;
  margin: -4rem -3rem -3rem -2rem;
  padding: 1rem;
  text-align: center;
  text-transform: uppercase;
  width: 23rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.home__lists{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 6rem 0 0 0;
}

.home__list{
  background: #fee533;
  text-align: center;
  margin: 1rem;
  width: 250px;
  min-height: 200px;
  border-radius: 5px;
  border: solid 2px;
  box-shadow: 0px 0px 6px 0px grey;
}

.home__list--title{
  font-size: 1.3rem;
  padding: 1rem 0 0.5rem ;
}

.home__list--img{
  width: 5rem;
}

.home__list--text{
  padding: 0.5rem 0.8rem;
}

.home__prestations{
  background-color: #393838;
  padding: 2rem;
  box-shadow: 2px 1px 4px 0px;
}

.home__prestations--title{
  font-size: 2rem;
  text-align: center;
  margin: 2rem;
  text-transform: uppercase;
  color: white;
}

.home__prestations--lists{
 display: flex;
    margin: 4em auto 1rem;
    text-align: center;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.home__prestations--list{
  padding: 0.4rem;
  width: 8rem;
}

.home__prestations--img{
  width: 2.5rem;
}

.home__prestations--text{
  color: white;
}
@media screen and (min-width:1440px){
  .home{
    margin: 0 15%;
  }
}

@media screen and (max-width:425px){

  .home__text--h1{
    width: 100vw;
  }

  .home__text--h2{
    width: 100vw;
  }

  .home__text--h3{
    width: 100vw;
  }
}
