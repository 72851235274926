@use 'reset';
@use 'vars';

*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: none;  
}

body{
  margin: 0;
}

ul{
  list-style-type: none;
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
}
