.carousel {
  position: relative;
  
  .thumbs{
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 10px;
  }

  .thumb{
    margin: 0 5px;
    cursor: pointer;
  }

  .overlay {
    width: 85%;
    position: inherit;
    transform: translateX(-10%)  translateY(-50%);
    left: 16vw;
    background: rgb(0, 0, 0);
    color: #fff;
    top: 45%;
    margin-top: 3.5rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

    &__text{
      padding: 4rem;
      text-align: center;
    }
  }
}

.control-arrow{
  width:10rem;
}

.carousel-root{
  background-color: rgb(255, 255, 255);
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 2rem;
}

.carousel.carousel-slider .control-arrow, .carousel .control-arrow{
  z-index: 1;
}

.carousel .control-next.control-arrow:before {
  border-left: 8px solid #010101;
}

.carousel .control-prev.control-arrow:before {
  border-right: 8px solid rgb(0, 0, 0);
}
@media screen and (min-width:1440px){

  .carousel .overlay {
    transform: translateX(-18%)  translateY(-50%);
  }
}

@media screen and (max-width: 600px){
  .carousel .slide img{
    height: 40vh;
    object-fit: cover;
  }
}

@media screen and (max-width: 425px){
  .carousel {
    display: none;
  }

  .carousel-root{
    padding: 0;
  }
}
