.history{
  padding-top: 6.5rem;
}

.history__title{
  background-color: #fee533;
    color: #e5322d;
    font-size: 1.8rem;
    font-weight: bolder;
    margin: 0rem 0 1.5rem 0;
    text-transform: uppercase;
    padding: 1rem;
    text-align: center;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
}

.history__content{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(0, 0, 0);
  background: rgb(255, 255, 255);
  
  &:nth-of-type(2n){
    flex-direction: row-reverse;
    background: #1d1d1d;
    color: #fff;
  }
}

.history__content--img{
  min-width: 10rem;
  width: 80%;
  margin: 1.5rem;
  box-shadow: 0 4px 8px 0 rgb(0 0 0), 0 6px 20px 0 rgb(0 0 0 / 68%);
}

.history__content--bloc{
  margin: 1.3rem;
  width: 40%;
}

.history__content--text{
  min-width: 15rem;
  padding-top: 1rem;
}

@media screen and (min-width:1440px){
  .history{
    margin: 0 15%;
  }
}

@media screen and (max-width:768px){
  
  .history__content{
    flex-wrap: wrap;
  }
  .history__content--bloc{
    width: 100%;
  }
  .history__content--img{
    width: 100%;
    padding: 0;
  }
}
